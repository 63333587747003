#page-map {
  width: 100vw;
  height: 100vh;
}


.company-list {
  margin-top: 20px;
  padding-right: 20px;
  flex: 1;
  height: 200px;
  overflow:auto;
}

.company-counter {
  text-align: center;
  font-size: 18px;
}

.card {
  padding: 20px;
  background: #fff;
  max-width: 100%;
  border-radius: 10px;
  margin: 60px auto;
  border: 1px solid #000;
  margin-top: 20px;
  margin-bottom: 20px;
}


.card h3 {
  text-align: center;
}

.card p {
  text-align: center;
}

.highlight {
  font-weight: bold;
  color: #294;
}


form.landing-page-form {
  width: 500px;

  background: #ffffff;
  border: 1px solid #c90738;
  border-radius: 15px;

  padding: 20px 40px;

  position: absolute;
  top: 30px;
  left: 50px;
  z-index: 1;
}

#page-map .leaflet-container {
  z-index: 0;
}

form.landing-page-form header {
  display: flex;
  align-items: center;
  height: 50px;
  justify-content: space-between;
}

.form-icon {
  margin-top: 10px;
  cursor: pointer;
}

.form-header {
  color: #c90738;
  width: 100%;
  font-size: 25px;
  line-height: 34px;
  font-weight: 700;
}

.how-to-span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.how-to-button {
  width: 100%;
  height: 32px;
  border: 0;
  cursor: pointer;
  background-color: #c90738;
  border-radius: 10px;
  color: #ffffff;
  font-weight: 800;
}



form.landing-page-form fieldset {
  border: 0;
}

form.landing-page-form fieldset legend {
  width: 100%;

  font-size: 24px;
  line-height: 34px;
  color: #c90738;
  font-weight: 700;

  border-bottom: 1px solid #c90738;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

form.landing-page-form .input-block  {
  z-index: 1;
  margin-top: 24px;
}

form.landing-page-form .input-block label {
  display: flex;
  color: #000;
  margin-bottom: 8px;
  line-height: 24px;
}

form.landing-page-form .input-block label span {
  font-size: 14px;
  color: #000;
  margin-left: 24px;
  line-height: 24px;
}

form.landing-page-form .input-block input {
  width: 100%;
  background: #f5f8fa;
  border: 1px solid #000;
  border-radius: 15px;
  outline: none;
  color: #000;
}

form.landing-page-form .input-block input {
  height: 44px;
  padding: 0 16px;
}

form.landing-page-form button.confirm-button {
  margin-top: 20px;
  width: 100%;
  height: 64px;
  border: 0;
  cursor: pointer;
  background-color: #c90738;
  border-radius: 15px;
  color: #ffffff;
  font-weight: 800;

  transition: background-color 0.2s;
}

form.landing-page-form button.confirm-button:hover {
  background-color: #c90738;
}

/* Pop Up - Marker */

#page-map .map-popup .leaflet-popup-content-wrapper {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 15px;
  box-shadow: none;
}

#page-map .map-popup .leaflet-popup-content h3 {
  color: #c90738;
  font-size: 20px;
  font-weight: bold;
  margin: 8px 12px;
}

#page-map .map-popup .leaflet-popup-content p {
  color: #042f38;
  font-size: 12px;
  font-weight: bold;
  margin: 8px 12px;
  line-height: 15px;
}

#page-map .map-popup .leaflet-popup-tip-container {
  display: none;
}

.collapse-css-transition {
  transition: height 280ms cubic-bezier(0.4, 0, 0.2, 1);
}

/* Styling react select */

.filter__control {
  border-radius: 15px !important;
  width: 100% !important;
  background: #f5f8fa !important;
  border: 1px solid #000 !important;
  border-radius: 15px !important;
  outline: none !important;
  color: #c90738 !important;
}

.filter__option {
  background: #f5f8fa !important;
  color: #c90738 !important;
}

.filter__option--is-focused {
  background: #d3e2e5 !important;
  color: #010101 !important;
}

@media (max-width: 575.98px) {
  form.landing-page-form {
    width: 90%;
    text-align: center;
    background: #ffffff;
    border: 1px solid #c90738;
    border-radius: 15px;
    padding: 10px 20px;
    z-index: 1;
    margin-left: -30px;
  }

  form.landing-page-form header {
    display: flex;
    align-items: center;
    height: 30px;
    justify-content: space-between;
  }
  
  .form-icon {
    margin-top: 10px;
    cursor: pointer;
    width: 30px;
  }
  
  .form-header {
    color: #c90738;
    width: 100%;
    font-size: 16px;
    line-height: 34px;
    font-weight: 700;
  }

  form.landing-page-form fieldset {
    border: 0;
  }
  
  form.landing-page-form fieldset legend {
    width: 100%;
    font-size: 20px;
    color: #c90738;
    font-weight: 700;
    border-bottom: 1px solid #c90738;
    margin-bottom: 5px;
    padding-bottom: 5px;
  }
  
  form.landing-page-form .input-block label {
    display: flex;
    color: #000;
    line-height: 24px;
    margin-top: -10px;
  }
  
  form.landing-page-form .input-block input {
    width: 100%;
    background: #f5f8fa;
    border: 1px solid #000;
    border-radius: 15px;
    outline: none;
    color: #000;
  }
  
  form.landing-page-form .input-block input {
    height: 34px;
  }
  
  form.landing-page-form button.confirm-button {
    margin-top: 14px;
  
    width: 100%;
    height: 48px;
    border: 0;
    cursor: pointer;
    background-color: #c90738;
    border-radius: 15px;
    color: #ffffff;
    font-weight: 800;
  
    transition: background-color 0.2s;
  }
  
  form.landing-page-form button.confirm-button:hover {
    background-color: #c90738;
  }

  .company-list {
    flex: 1;
    height: 100px;
    overflow:auto;
    justify-content: center;
    align-items: center;
  }
  
  .company-counter {
    text-align: center;
    font-size: 16px;
    display: none;
  }
  
  .card {
    padding: 15px;
    background: #fff;
    width: 100%;
    border-radius: 10px;
    border: 1px solid #000;
    margin-top: 0px;
    margin-left: 10px;
    margin-bottom: 10px;
  }
  
  
  .card h3 {
    text-align: center;
    font-size: 16px;
  }
  
  .card p {
    text-align: center;
    font-size: 14px;
  }
  
  
 }

@media (max-width: 767.98px) {  }

@media (max-width: 991.98px) {  }

@media (max-width: 1199.98px) {  }

@media (max-width: 1399.98px) {  }